.availability-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}

.date-picker {
    display: flex;
    margin-bottom: 20px;
}

.date-picker label {
    font-weight: bold;
    margin-right: 10px;
}

.date-picker input[type="date"] {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.day-inputs {
    margin-bottom: 20px;
}

.day-inputs label {
    font-weight: bold;
    margin-right: 10px;
}

.day-inputs input[type="time"] {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.availability-buttons {
    margin-bottom: 10px;
}