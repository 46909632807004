.smart-scheduler {
  width: 100%;
  height: 100%;
  border-radius: initial;
}

#primaryContainer.adminCalendar {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  &.collapse {
    #sideA {
      width: 0;
    }

    #sideB {
      width: 100%;
    }

    #addNew {
      width: 45px;
      border-radius: 50%;

      &::after {
        left: 0;
        width: 100%;
        height: 100%;
      }

      span {
        display: none;
      }
    }
  }

  #sideA {
    width: 280px;
    transition: width 0.2s ease-in-out;
    border-top: var(--smart-border-width) solid var(--smart-border);

    @media (max-width: 768px) {
      width: auto;
    }

    .controls-container {
      box-sizing: border-box;
      width: 100%;
      padding: 20px;
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 100%;
      grid-row-gap: 20px;
      overflow: auto;

      @media (max-width: 768px) {
        gap: 0;
      }
    }

    .smart-calendar {
      min-width: initial;
      max-width: 100%;
      height: 250px;
      min-height: initial;
      --smart-font-size: 12px;
      --smart-calendar-cell-size: 26px;
      --smart-calendar-cell-spacing: 4px;
      border: initial;
      --smart-surface: transparent;
    }
  }

  #sideB {
    width: auto;
    transition: width 0.2s ease-in-out;

    @media (min-width: 769px) {
      width: calc(100% - 280px);
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    box-sizing: border-box;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  #addNew {
    position: relative;
    left: 5px;
    margin-top: 25px;
    cursor: pointer;
    height: 45px;
    border-radius: 15px;
    box-shadow: var(--smart-elevation-2);
    z-index: 1;

    button {
      padding-left: 25px;
    }

    &::after {
      content: "+";
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 10px;
      width: 30px;
      height: 100%;
      font-family: var(--smart-font-family-icon);
      font-size: 40px;
      color: inherit;
      background: linear-gradient(217deg, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  #toggleButton {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: initial;
    background: transparent;
    margin-right: 10px;

    &::after {
      content: var(--smart-icon-align-left);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-family: var(--smart-font-family-icon);
      font-size: 25px;
      box-sizing: border-box;
      pointer-events: none;
    }
  }

  #scheduler-header {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: var(--smart-background);
    color: var(--smart-background-color);
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
}

#title {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 20px;
  position: relative;
}

#logo {
  content: "";
  position: relative;
  width: 100%;
  height: 90px;
  filter: invert(0.8);
  box-sizing: border-box;
}

.button-container {
  height: 150px;
  display: none;
}

.smart-input,
.smart-tree {
  width: 100%;

  &.smart-tree {
    height: 200px;
    border: initial;
    margin-top: 22px;
  }
}

.smart-tree {
  &[selection-mode="checkBox"] {
    smart-tree-items-group > .smart-tree-item-label-container {
      &::before,
      &::after {
        content: initial !important;
      }
    }
  }

  .smart-tree-item {
    &[value="london"] {
      --smart-primary: cornflowerblue;
    }

    &[value="edinburgh"] {
      --smart-primary: green;
    }

    &[value="manchester"] {
      --smart-primary: purple;
    }

    &[value="homevisit"] {
      --smart-primary: orange;
    }

    &[value="virtual"] {
      --smart-primary: yellow;
    }
  }
}

.london,
.edinburgh,
.manchester,
.homevisit,
.virtual {
  --smart-scheduler-event-background-rgb: if(
    &== ".london",
    100,
    149,
    237,
    if(
      &== ".edinburgh",
      0,
      129,
      0,
      if(&== ".manchester", 128, 0, 128, if(&== ".homevisit", 255, 165, 0, if(&== ".virtual", 255, 255, 0)))
    )
  );
  --smart-scheduler-event-background: rgba(var(--smart-scheduler-event-background-rgb), 1);
  --smart-scheduler-event-focus: rgba(var(--smart-scheduler-event-background-rgb), 0.9);
  --smart-scheduler-event-hover: rgba(var(--smart-scheduler-event-background-rgb), 0.8);
}

#watermark {
  display: none !important;
}
