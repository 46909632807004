.tooltipContainer::before {
  content: "";
  position: absolute;
  top: 0px;
  left: -6px;
  border-style: solid;
  border-width: 8px 4px 0px 6px;
  border-color: #ffffff transparent transparent;
  z-index: 11;
}

.tooltipContainer::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -8px;
  border-style: solid;
  border-width: 10px 4px 0px 7px;
  border-color: rgb(229 231 235) transparent transparent;
  z-index: 10;
}
